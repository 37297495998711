<template>
    <div class="header">
        <div ref="asss" class="flex header_left">
            <a href="/Home" class="decoration_box">
                <div class="galileo_logo">
                    <img src="../../public/images/msi/galileo.png" alt="" />
                </div>
            </a>
            <div class="fold_icon">
                <i v-show="!isFold && $route.path != '/Home'" class="el-icon-s-fold" @click="handleFoldMenus(true)"></i>
                <i v-show="isFold && $route.path != '/Home'" class="el-icon-s-unfold" @click="handleFoldMenus(false)"></i>
            </div>
            <ul class="menus flex">
                <template v-for="item in menus">
                    <li :class="{ active: item.menuId == activeMenuId }" :key="item.name" v-if="item.name" @click="handleMenu(item)">{{ item.name }}</li>
                </template>
            </ul>
        </div>
        <div class="header_right">
            <div class="userinfo">
                <div class="logo">
                    <img src="../../public/images/logo1.png" alt="" />
                </div>
                <el-dropdown trigger="click">
                    <span class="el-dropdown-link"> {{ userInfo.nickname }}<i class="el-icon-caret-bottom el-icon--right"></i> </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item class="clearfix" @click.native="centerDialogVisible = true">修改密码</el-dropdown-item>
                        <el-dropdown-item class="clearfix" @click.native="handleLogOut">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
        <el-dialog title="修改密码" width="600px" :visible.sync="centerDialogVisible" center>
            <el-form @keyup.enter.native="handleLogin" ref="form" :model="form">
                <el-form-item label="原始密码">
                    <el-input v-model="form.oldPassword" placeholder="请输入原始密码" type="password"></el-input>
                </el-form-item>
                <el-form-item label="新密码">
                    <el-input v-model="form.newPassword" placeholder="请输入新密码" type="password"></el-input>
                </el-form-item>
                <el-form-item label="重复新密码">
                    <el-input v-model="form.newPassword2" placeholder="请重复输入新密码" type="password"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="centerDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="handleEditPassword">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog class="policeDialog" title="警情处理" width="900px" :visible.sync="policeVisible" :close-on-press-escape="false" :close-on-click-modal="false" center @close="closePolice">
            <div style="margin-bottom: 20px; margin-top: -20px">
                <span>设备号：{{ warningData.device_number ? warningData.device_number : '--' }}</span>
                <span style="margin-left: 20px; color: red">紧急告警</span>
            </div>
            <!-- v-if="policeVisible" -->
            <div class="policeBox clearfix">
                <div class="box_left fl" v-for="(item, index) in videoList" :key="index">
                    <streaming-media-player :ref="'mediaHeaderRef' + index" :mediaPlayerData="item" :hideCloseBtn="true" :mediaIndex="index" @sreamEnd="sreamEnd" @sreamStart="sreamStart" @clearPlayTime="clearPlayTime"></streaming-media-player>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="closeWarning">确认警情</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import StreamingMediaPlayer from './StreamingMediaPlayer.vue';
export default {
    name: 'MyHeader',
    props: {
        msg: String,
        checkedMenu: Object
    },
    components: {
        StreamingMediaPlayer
    },
    data() {
        return {
            isFold: false,
            form: {
                oldPassword: '',
                newPassword: '',
                newPassword2: ''
            },
            centerDialogVisible: false,
            username: '',
            menus: [],
            menusObj: [],
            activeMenuId: '',
            policeVisible: false,
            countdown1: null,
            countdown2: null,
            videoList: [
                {
                    name: 'xxx视频',
                    url: 'xxxxx.live.flv',
                    poster: 'xxx.jpg',
                    hideVideoFlag: false,
                    autoPlay: true
                },
                {
                    name: 'xxx视频',
                    url: 'xxxxx.live.flv',
                    poster: 'xxx.jpg',
                    hideVideoFlag: false,
                    autoPlay: true
                }
            ],
            warningList: [],
            warningData: {},
            currentDeviceNumber: null,
            openDialogFlag: null,
            pageStatus: 1,
            warningObj: {}
        };
    },
    methods: {
        //操作修改密码
        handleEditPassword() {
            if (this.form.newPassword != this.form.newPassword2) {
                this.$message.error('抱歉，您两次输入的新密码不一致');
                return false;
            }
            var json = {
                oldPassword: this.form.oldPassword,
                newPassword: this.form.newPassword
            };
            this.instance.post('/upms/api/user/changePassword', json).then((res) => {
                var data = res.data;
                // this.$message(data.message)
                if (data.code === 200) {
                    this.centerDialogVisible = false;
                    this.$confirm('密码修改成功，请重新登录。', '提示', {
                        confirmButtonText: '确定',
                        showCancelButton: false,
                        // cancelButtonText: '取消',
                        type: 'warning'
                    })
                        .then(() => {
                            this.$router.replace({
                                path: '/'
                            });
                        })
                        .catch(() => {
                            this.$router.replace({
                                path: '/'
                            });
                        });
                    // this.$router.replace({path:'/'});
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        //操作退出
        handleLogOut() {
            this.$confirm('此操作将退出当前登录, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    localStorage.clear();
                    sessionStorage.clear();
                    this.$router.replace({
                        path: '/'
                    });
                    location.reload();
                    this.$message({
                        type: 'success',
                        message: '退出成功!'
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
        },
        handleMenu(item, clickMenu) {
            this.activeMenuId = item.menuId;
            if (localStorage.getItem('headerMenus')) {
                this.menusObj = JSON.parse(localStorage.getItem('menusObj'));
            }

            localStorage.setItem('checkedMenu', JSON.stringify(this.menusObj[item.name]));
            localStorage.setItem('checkedMenuId', this.activeMenuId);
            this.$emit('clickMenu', this.menusObj[item.name]);
            if (!clickMenu) {
                this.$router.push({ path: item.linkage }, () => {});
            }
        },
        //点击面包屑，是否展开菜单
        handleFoldMenus(isFold) {
            this.isFold = isFold;
            this.$emit('handleFoldMenus', isFold);
        },
        sreamStartPlay(playData) {
            let clientId = playData.deviceNumber;
            let tmpOrigin = location.origin.indexOf('localhost') != -1 ? 'http://ubiallinone-test.sigreal.cn' : location.origin;
            this.videoList[playData.channelNo - 1].url = tmpOrigin + '/video/0' + clientId + '-' + playData.channelNo;
            this.videoList[playData.channelNo - 1].deviceNumber = clientId;

            console.log('channelNo:' + playData.channelNo);
            setTimeout(() => {
                let tmpRefEl = this.$refs['mediaHeaderRef' + (playData.channelNo - 1)];

                if (!playData.playFlag) {
                    if (tmpRefEl && tmpRefEl.length > 0) {
                        this.$refs['mediaHeaderRef' + (playData.channelNo - 1)][0].startPlay();
                    } else if (tmpRefEl) {
                        this.$refs['mediaHeaderRef' + (playData.channelNo - 1)][0].startPlay();
                    }
                } else {
                    let tmpItem = JSON.parse(JSON.stringify(this.videoList[playData.channelNo - 1]));
                    tmpItem.stopFlag = false;
                    tmpItem.play = true;
                    tmpItem.loading = false;
                    tmpItem.pause = false;

                    this.videoList[playData.channelNo - 1] = null;
                    this.videoList[playData.channelNo - 1] = tmpItem;
                    this.$forceUpdate();

                    if (tmpRefEl && tmpRefEl.length > 0) {
                        this.$refs['mediaHeaderRef' + (playData.channelNo - 1)][0].rePlay();
                    } else if (tmpRefEl) {
                        this.$refs['mediaHeaderRef' + (playData.channelNo - 1)][0].rePlay();
                    }
                }
            }, 100);

            this['countdown' + playData.channelNo] = setTimeout(() => {
                console.log('停止播放');
                this.sreamEnd(playData.channelNo);
            }, 60000);
        },
        clearPlayTime() {
            console.log('清延迟器');
            clearTimeout(this.countdown1);
            clearTimeout(this.countdown2);
        },
        closePlayer(channelNo) {
            clearTimeout(this['countdown' + channelNo]);
            if (this.$refs['mediaHeaderRef' + (channelNo - 1)]) {
                this.$refs['mediaHeaderRef' + (channelNo - 1)][0].cancelSubscription();
            }
        },
        sreamStart(playData) {
            console.log('start----------------------------------');
            console.log(this.$route.path);

            this.instance.post('/ubi/api/advance_device/realtimeStreamStart', { clientId: playData.deviceNumber, channelNo: playData.channelNo }).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    if (data.data.code == 4000) {
                        this.$message.error(data.data.msg);
                        return;
                    }

                    let clientId = data.data.data.clientId;
                    let tmpOrigin = location.origin.indexOf('localhost') != -1 ? 'http://ubiallinone-test.sigreal.cn' : location.origin;
                    this.videoList[playData.channelNo - 1].url = tmpOrigin + '/video/0' + clientId + '-' + playData.channelNo;
                    this.videoList[playData.channelNo - 1].deviceNumber = clientId;

                    console.log('channelNo:' + playData.channelNo);
                    let tmpRefEl = this.$refs['mediaHeaderRef' + (playData.channelNo - 1)];

                    if (!playData.playFlag) {
                        if (tmpRefEl && tmpRefEl.length > 0) {
                            this.$refs['mediaHeaderRef' + (playData.channelNo - 1)][0].startPlay();
                        } else if (tmpRefEl) {
                            this.$refs['mediaHeaderRef' + (playData.channelNo - 1)][0].startPlay();
                        }
                    } else {
                        let tmpItem = JSON.parse(JSON.stringify(this.videoList[playData.channelNo - 1]));
                        tmpItem.stopFlag = false;
                        tmpItem.play = true;
                        tmpItem.loading = false;
                        tmpItem.pause = false;

                        this.videoList[playData.channelNo - 1] = null;
                        this.videoList[playData.channelNo - 1] = tmpItem;
                        this.$forceUpdate();

                        if (tmpRefEl && tmpRefEl.length > 0) {
                            this.$refs['mediaHeaderRef' + (playData.channelNo - 1)][0].rePlay();
                        } else if (tmpRefEl) {
                            this.$refs['mediaHeaderRef' + (playData.channelNo - 1)][0].rePlay();
                        }
                    }

                    this['countdown' + playData.channelNo] = setTimeout(() => {
                        console.log('停止播放');
                        this.sreamEnd(playData.channelNo);
                    }, 60000);
                }
            });
        },
        sreamEnd(channelNo) {
            console.log('header end');
            this.instance.post('/ubi/api/advance_device/realtimeStreamEnd', { clientId: this.currentDeviceNumber, channelNo: channelNo }).then((res) => {
                this.closePlayer(channelNo);
                let data = res.data;
                if (data.code == 200) {
                    this.videoList[channelNo - 1].stopFlag = true;
                    this.videoList[channelNo - 1].play = false;
                    this.videoList[channelNo - 1].loading = false;
                    this.videoList[channelNo - 1].pause = false;
                    let tmpItem = JSON.parse(JSON.stringify(this.videoList[channelNo - 1]));
                    this.videoList[channelNo - 1] = null;
                    this.videoList[channelNo - 1] = tmpItem;
                    this.$forceUpdate();
                } else {
                    console.log(data.data);
                }
            });
        },
        closePolice() {
            this.clearPlayTime();
            if (this.pageStatus && this.$refs.mediaHeaderRef0 && this.$refs.mediaHeaderRef0[0]) {
                this.$refs.mediaHeaderRef0[0].cancelSubscription();
                this.$refs.mediaHeaderRef1[0].cancelSubscription();
            }
        },
        showWarningDialog(closePoliceFlag) {
            var delIndexArr = [];
            for (const key in this.warningObj) {
                this.warningList.forEach((item, index) => {
                    if (item.alarm_id == key) {
                        delIndexArr.push(index);
                    }
                });
            }

            if (delIndexArr.length > 0) {
                console.log('需要关闭的告警索引');
                console.log(delIndexArr);
                var tmpDeviceNumber = this.warningList[0] ? this.warningList[0].device_number : null;
                for (var i = delIndexArr.length - 1; i >= 0; i--) {
                    var indexToDelete = delIndexArr[i];
                    this.warningList.splice(indexToDelete, 1);
                }

                console.log('告警列表:' + closePoliceFlag);

                console.log(this.warningList);
                if (this.warningList.length == 0) {
                    this.policeVisible = false;
                    return;
                    // setTimeout(() => {
                    //     this.$emit('videoMapPlay');
                    // }, 2000);
                }
            }

            if (this.warningList.length > 0 && !this.policeVisible && this.pageStatus) {
                console.log('告警弹窗数据：');
                console.log(this.warningList);

                this.openDialogFlag = true;
                this.warningData = this.warningList[0];
                this.policeVisible = true;
                this.currentDeviceNumber = this.warningData.device_number;

                console.log(this.$route.path);

                console.log('关闭地图页面');

                this.$emit('destroyMapPlay');
                // this.$emit('canMapPlay');

                // this.sreamStartPlay({ deviceNumber: this.currentDeviceNumber, channelNo: 1, playFlag: this.openDialogFlag });
                // this.sreamStartPlay({ deviceNumber: this.currentDeviceNumber, channelNo: 2, playFlag: this.openDialogFlag });

                // console.log('start warning ');
                this.sreamStart({ deviceNumber: this.currentDeviceNumber, channelNo: 1, playFlag: this.openDialogFlag });
                this.sreamStart({ deviceNumber: this.currentDeviceNumber, channelNo: 2, playFlag: this.openDialogFlag });
            }
        },
        closeWarning() {
            this.$refs.mediaHeaderRef0[0].cancelSubscription();
            this.$refs.mediaHeaderRef1[0].cancelSubscription();
            this.instance.post('/ubi/api/emergency_alarm/close', { alarmId: this.warningData.alarm_id, clientId: this.currentDeviceNumber }).then((res) => {
                this.clearPlayTime();
                let data = res.data;
                if (data.code == 200) {
                    this.policeVisible = false;
                    this.currentDeviceNumber = null;
                    this.warningData = {};
                    let pathStr = this.$route.path;
                    if (pathStr == '/devices/warningList') {
                        let btnEl = document.querySelectorAll('.queryBtn');
                        btnEl[0] && btnEl[0].click();
                    }
                    setTimeout(() => {
                        this.showWarningDialog(true);
                    }, 1000);
                }
            });
        },
        handleVisibilityChange() {
            if (document.visibilityState == 'hidden') {
                this.closePolice();
                this.pageStatus = 0;
                this.policeVisible = false;
            } else if (document.visibilityState == 'visible') {
                this.pageStatus = 1;
                this.showWarningDialog();
            }
        },
        onmessageWS(event) {
            let _this = this;
            switch (event.type) {
                case 'onmessageWS':
                    let msgData = event.detail.data;
                    if (msgData.alarm_id) {
                        if (msgData.alarm_status) {
                            _this.warningObj[msgData.alarm_id] = msgData;
                        } else {
                            const isExist = _this.warningList.find((item) => item.alarm_id === msgData.alarm_id);

                            !isExist && _this.warningList.push(msgData);
                            !_this.policeVisible && _this.showWarningDialog();
                        }
                    }

                    break;
            }
        }
    },
    mounted() {
        console.log('当前页面');

        console.log(this.$route.path);

        this.$websocket.close();
        this.$getWebsocketToken();
        this.warningObj = {};
        this.username = localStorage.getItem('username') || '匿名用户';
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.activeMenuId = localStorage.getItem('checkedMenuId') ? localStorage.getItem('checkedMenuId') : '2';
        let tmpPath = this.$route.path;
        let tmpPathArr = tmpPath.split('/');
        let tmpClickItem = null;
        let _this = this;
        if (!localStorage.getItem('headerMenus')) {
            let routesTimer = setInterval(() => {
                if (JSON.parse(localStorage.getItem('routes'))) {
                    clearInterval(routesTimer);
                    let tmpMenus = [];
                    let tmpRoutes = JSON.parse(localStorage.getItem('routes')) || [];
                    let tmpItem = {};
                    let tmpMenusObj = {};
                    tmpRoutes.forEach((ele) => {
                        if (ele.children && ele.children.length > 0) {
                            tmpItem = {
                                menuId: ele.meta.menuId,
                                name: ele.meta.title,
                                linkage: ele.path + '/' + ele.children[0].path,
                                parentPath: ele.path
                            };
                            tmpMenus.push(tmpItem);
                            if (tmpPathArr.length > 0 && ele.name == tmpPathArr[1]) {
                                this.activeMenuId = ele.meta.menuId;
                                tmpClickItem = tmpItem;
                            }
                        } else if (ele.meta && !ele.meta.showHidden) {
                            tmpItem = {
                                menuId: ele.meta.menuId,
                                name: ele.meta.title,
                                linkage: ele.path,
                                parentPath: ele.path
                            };
                            tmpMenus.push(tmpItem);
                        }
                        ele.meta ? (this.menusObj[ele.meta.title] = ele) : '';
                        ele.meta ? (tmpMenusObj[ele.meta.title] = ele) : '';
                    });
                    this.menus = tmpMenus;
                    tmpClickItem && this.handleMenu(tmpClickItem, true);
                    localStorage.setItem('headerMenus', JSON.stringify(tmpMenus));
                    localStorage.setItem('menusObj', JSON.stringify(tmpMenusObj));
                }
            }, 100);
        } else {
            this.menus = JSON.parse(localStorage.getItem('headerMenus'));
            this.menus.forEach((item) => {
                if (tmpPathArr.length > 0 && item.parentPath.slice(1, item.parentPath.length) == tmpPathArr[1]) {
                    this.activeMenuId = item.menuId;
                    tmpClickItem = item;
                }
            });
            tmpClickItem && this.handleMenu(tmpClickItem, true);
        }

        window.addEventListener('onmessageWS', this.onmessageWS);

        document.addEventListener('visibilitychange', this.handleVisibilityChange);
    },
    beforeDestroy() {
        document.removeEventListener('visibilitychange', this.handleVisibilityChange);
        window.removeEventListener('onmessageWS', this.onmessageWS);
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
/deep/.el-dialog--center .el-dialog__body {
    padding: 25px 80px 30px;
}
/deep/ .el-dropdown {
    font-size: 16px;
}
.header {
    width: 100%;
    height: 0.8rem;
    padding: 0 0.36rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #0b0f35;
    /* box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.5); */
    /* border-radius: 0px 0px 0.2rem 0.2rem; */
    color: #ffffff;
}
.userinfo {
    display: flex;
    align-items: center;
}
.logo {
    width: 90px;
}
.decoration_box {
    display: flex;
    align-items: center;
    width: 4.2rem;
}
.decoration_title {
    margin-left: 0.2rem;
    font-size: 0.28rem;
    font-family: Source Han Sans CN;
    font-weight: 800;
    color: #8bd2eb;
    background: linear-gradient(0deg, #80cee3 0%, #e3f9ff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.decoration_title .title_left {
    font-size: 0.16rem;
    /* margin-right:0.1rem; */
    width: 0.8rem;
    text-align: center;
}
.decoration_title p {
    line-height: 1.2;
}
.header_left {
    width: 26rem;
    justify-content: flex-start;
}
.header_right {
    min-width: 4rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.header_right .el-dropdown {
    color: #fff;
    margin-left: 0.3rem;
    cursor: pointer;
}
.galileo_logo {
    width: 3.8rem;
}
.decoration {
    width: 0.8rem;
    margin-right: 0.24rem;
}
.menus {
    /* width: 4.58rem; */
    /* margin-left:1.2rem; */
    font-size: 0.2rem;
}
.menus li {
    /* padding: 0 0.3rem; */
    /* display: contents; */
    margin-left: 0.4rem;
}
.fold_icon i {
    font-size: 0.5rem;
    cursor: pointer;
}
.menus li,
.decoration_box,
.contact {
    cursor: pointer;
}
.menus li:hover {
    color: #30fbfe;
    font-weight: 500;
}
.menus .active {
    color: #30fbfe;
    font-weight: 500;
}
.policeDialog {
    /deep/ .el-dialog__body {
        padding: 20px 22px;
    }
}
.policeBox {
    .box_left,
    .box_right {
        width: 420px;
        // height: 300px;
        // border: 1px solid red;
        margin-right: 5px;
    }
}
</style>
