<template>
    <div ref="mediaPlayerRef" class="mediaPlayer">
        <div class="showVideo" @click="(e) => hideVideo(true, e)" v-show="mediaPlayerData.hideVideoFlag">
            <i class="el-icon-video-camera"></i>
        </div>
        <div class="video-box videoDiv" ref="videoBox" v-show="!mediaPlayerData.hideVideoFlag">
            <i class="closeBtn el-icon-close" v-if="!hideCloseBtn && (!mediaPlayerData.hideVideoFlag || !mediaPlayerData.loading)" @click="(e) => hideVideo(null, e)"></i>
            <div :class="'item-video-box' + (mediaPlayerData.full ? ' full' : '')">
                <div>
                    <video :ref="'videoRef' + mediaIndex" class="video-js" loop muted controls autoplay preload="auto" :poster="mediaPlayerData.poster" @canplay="canplay" @play="videoPlay" :data-index="mediaIndex"></video>
                    <div :ref="'btnRef' + mediaIndex" :class="'btn' + (mediaPlayerData.play ? ' hid' : mediaPlayerData.loading ? ' loading' : mediaPlayerData.pause ? ' play' : ' play')" @click="(e) => playRun(e, mediaIndex)" :data-index="mediaIndex" v-show="!mediaPlayerData.play">
                        <div></div>
                    </div>
                    <!-- <div v-if="mediaPlayerData.play" @click="videoFull" :data-index="mediaIndex" :class="'full-screen' + (mediaPlayerData.full ? ' full' : '')"></div> -->
                </div>
            </div>
            <!-- <div class="item-video-name">{{ mediaPlayerData.name }}</div> -->
        </div>
    </div>
</template>
<script>
import flvjs from 'flv.js/dist/flv.min.js';
export default {
    name: 'MediaPlayer',
    props: {
        mediaPlayerData: {
            type: Object,
            default() {
                return {};
            }
        },
        mediaIndex: {
            type: Number,
            default() {
                return null;
            }
        },
        hideCloseBtn: {
            type: Boolean,
            default() {
                return false;
            }
        }
    },
    data() {
        return {
            videoData: {
                name: 'xxx视频',
                url: 'xxxxx.live.flv',
                poster: 'xxx.jpg',
                hideVideoFlag: false
            },
            flvPlayerInstance: null,
            flvPlayerFlag: 0,
            flvPlayerTime: null,
            flvPlayerNum: 0,
            resetPlayStatusNum: 0,
            stopPlayTime: null,
            tmpStatSpeed: 1,
            playerLoading: false,
            playWatchStatus: null
        };
    },
    mounted() {},
    methods: {
        hideVideo(showFlag) {
            console.log('hide video :' + showFlag);
            if (showFlag) {
                this.mediaPlayerData.hideVideoFlag = false;
                this.connect(this.mediaIndex + 1, true);
            } else {
                this.mediaPlayerData.hideVideoFlag = true;
                this.disconnect(this.mediaIndex + 1);
            }
        },
        // 全屏
        videoFull(e) {
            let self = this;
            let url = this.mediaPlayerData;
            if (url.full) {
                url.full = false;
            } else {
                url.full = true;
            }
            this.$forceUpdate();
        },

        // 播放
        videoPlay(e) {
            let self = this;
            let url = this.mediaPlayerData;
            if (url.stopTime) return;

            // 倒计时
            url.stopTime = setTimeout(() => {
                this.flvPlayerInstance.pause();
                url.play = false;
                url.pause = true;
                url.loading = false;
                // url.full = false;
                self.$forceUpdate();
            }, 10 * 60 * 1000);
        },

        // 可以播放
        canplay(e) {
            let self = this;
            let url = this.mediaPlayerData;
            let py = e.target;
            if (url.play) return;
            let urlIndex = py.getAttribute('data-index');
            this.itemVideo = urlIndex;
            this.flvPlayerInstance && this.flvPlayerInstance.play();
            url.play = true;
            url.pause = false;
            url.loading = false;
            self.$forceUpdate();
        },
        playRun(e, videoIndex, resetFlag) {
            console.log('playRun-videoIndex:' + videoIndex);

            if (this.mediaPlayerData.stopFlag || !this.mediaPlayerData.autoPlay) {
                this.connect(this.mediaIndex + 1, true);
                return;
            }

            let py,
                self = this;

            if (!e.target.classList.contains('btn')) {
                py = e.target.parentNode;
            } else {
                py = e.target;
            }

            let url = this.mediaPlayerData;
            let liveVideo = py.parentNode.getElementsByClassName('video-js')[0];
            let urlIndex = py.getAttribute('data-index');
            this.itemVideo = urlIndex;

            if (url.play) {
                this.flvPlayerInstance.pause();
                url.play = false;
                url.pause = true;
                url.loading = false;
                clearTimeout(url.stopTime);
                url.stopTime = null;
                self.$forceUpdate();
                return;
            } else if (url.pause) {
                this.flvPlayerInstance.play();
                url.play = true;
                url.pause = false;
                url.loading = false;
                self.$forceUpdate();
                return;
            }

            url.play = false;
            url.pause = false;
            url.loading = true;
            self.$forceUpdate();

            this.flvPlayerInstance = flvjs.createPlayer({
                type: 'flv',
                isLive: true,
                cors: true,
                hasAudio: true,
                autoCleanupSourceBuffer: false,
                url: url.url
            });

            this.flvPlayerInstance.attachMediaElement(liveVideo);
            this.flvPlayerInstance.load();

            clearInterval(this.flvPlayerTime);
            this.watchPlayStatus();
        },
        rePlay() {
            console.log('rePlay:' + this.mediaIndex);

            let tmpUrl = this.mediaPlayerData;
            // let tmpLive = document.getElementsByClassName('video-js')[this.mediaIndex];
            let tmpLive = this.$refs['videoRef' + this.mediaIndex];

            this.flvPlayerInstance = null;
            this.flvPlayerInstance = flvjs.createPlayer({
                type: 'flv',
                isLive: true,
                autoCleanupSourceBuffer: false,
                url: tmpUrl.url
            });
            this.flvPlayerInstance.attachMediaElement(tmpLive);
            this.flvPlayerInstance.load();
            this.flvPlayerInstance.play();

            clearInterval(this.flvPlayerTime);
            this.watchPlayStatus();
        },
        // 监听播放状态
        watchPlayStatus() {
            let _this = this;

            this.flvPlayerInstance.play().then(() => {
                console.log('重置播放：');

                console.log('Playback started successfully');
                this.flvPlayerFlag = 1;
                this.stopPlayTime = null;
                this.playerLoading = false;
            });

            let playerHeatNum = 0;
            this.flvPlayerInstance.on(flvjs.Events.STATISTICS_INFO, function (stat) {
                if (_this.flvPlayerFlag && !_this.stopPlayTime && stat.speed == 0) {
                    playerHeatNum++;
                    _this.tmpStatSpeed = stat.speed;
                    _this.stopPlayTime = setTimeout(() => {
                        clearTimeout(_this.stopPlayTime);
                        _this.checkPlayerBuffer(stat.speed, playerHeatNum, true);
                    }, 5000);
                }
            });

            this.flvPlayerTime = setInterval(() => {
                this.flvPlayerNum++;

                if (this.flvPlayerNum > 3) {
                    this.flvPlayerNum = 0;
                    clearInterval(this.flvPlayerTime);
                    this.flvPlayerTime = null;
                    console.log('重试定时器停止');
                } else {
                    if (this.flvPlayerFlag) {
                        console.log('正常播放，清除重试机制');

                        clearInterval(this.flvPlayerTime);
                        this.flvPlayerTime = null;
                    } else if (!this.flvPlayerFlag && this.resetPlayStatusNum < 5) {
                        console.log('播放失败，4s后重试。。。');
                        this.flvPlayerFlag = 0;
                        this.resetPlayStatusNum++;
                        if (this.resetPlayStatusNum < 5 && this.$refs['videoRef' + this.mediaIndex]) {
                            this.$emit('clearPlayTime');
                            this.cancelSubscription();
                            this.reconnectMedit();
                        } else {
                            console.log('重试失败，关闭重试。。。');
                        }
                    } else {
                        console.log('播放按钮停止重试');
                    }
                }
            }, 4000);
        },
        reconnectMedit() {
            let _this = this;
            let tmpDevice = this.mediaPlayerData.deviceNumber;
            let tmpChannelNo = this.mediaIndex + 1;
            this.instance.post('/ubi/api/advance_device/realtimeStreamEnd', { clientId: tmpDevice, channelNo: tmpChannelNo }).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.instance.post('/ubi/api/advance_device/realtimeStreamStart', { clientId: tmpDevice, channelNo: tmpChannelNo }).then((res) => {
                        let data = res.data;
                        if (data.code == 200) {
                            setTimeout(() => {
                                _this.rePlay();
                            }, 1000);
                        }
                    });
                } else {
                    console.log(data.data);
                }
            });
        },
        checkPlayerBuffer(bufferData, heatNum, streamStopFlag) {
            if (!streamStopFlag) {
                console.log('正在播放，终止check');

                return;
            }

            // 流停止，需要播放器暂停
            if (streamStopFlag && heatNum > 10) {
                console.log('---------------');
                console.log(this.tmpStatSpeed);
                console.log(bufferData);

                console.log('直播流停止，需要暂停播放器；');
                this.disconnect(this.mediaIndex + 1);
                return;
            }

            // bufferData
            if (!this.mediaPlayerData.play && heatNum > 15) {
                console.log('播放异常，重新拉流');
                this.disconnect(this.mediaIndex + 1);
                setTimeout(() => {
                    this.connect(this.mediaIndex + 1, true);
                }, 1000);
            }
        },
        cancelSubscription() {
            if (this.flvPlayerInstance) {
                console.log('cancelSubscription');

                this.flvPlayerInstance.pause();
                this.flvPlayerInstance.unload();
                this.flvPlayerInstance.detachMediaElement();
                this.flvPlayerInstance.destroy();
                this.flvPlayerInstance = null;
            }
        },
        startPlay() {
            if (this.$refs['btnRef' + this.mediaIndex].length > 0) {
                this.$refs['btnRef' + this.mediaIndex][0].click();
            } else {
                this.$refs['btnRef' + this.mediaIndex].click();
            }
        },
        disconnect(channelNo) {
            console.log('emit end');

            this.flvPlayerFlag = 0;
            this.$emit('sreamEnd', channelNo);
        },
        connect(channelNo, playFlag) {
            this.$emit('sreamStart', { deviceNumber: this.mediaPlayerData.deviceNumber, channelNo: channelNo, playFlag: playFlag });
        }
    }
};
</script>
<style lang="less" scoped>
.mediaPlayer {
    .showVideo {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #adadad;
        line-height: 45px;
        text-align: center;
        cursor: pointer;
        margin-bottom: 30px;
        i {
            color: #fff;
            font-size: 19px;
        }
    }
    .videoDiv {
        width: 100%;
        // background: #000;
        // margin-bottom: 30px;
        position: relative;
        .closeBtn {
            cursor: pointer;
            position: absolute;
            right: 5px;
            top: 10px;
            width: 20px;
            height: 20px;
            color: #fff;
            z-index: 12;
            font-size: 20px;
        }
    }

    .video-box {
        position: relative;
        width: 100%;
        height: 230px;
        overflow: hidden;
        > div {
            position: relative;
            display: inline-block;
            vertical-align: top;
            width: 33.33333%;
            padding: pointSize(16rem); // &::before {
            //     content: "";
            //     display: block;
            //     position: relative;
            //     padding-top: 75%;
            // }
        }

        .item-video-box {
            position: relative;
            width: 100%;
            &::before {
                content: '';
                display: block;
                position: relative;
                padding-top: 56.25%;
            }
            &.full {
                height: 100%;
                position: fixed;
                left: 0;
                top: 0;
                z-index: 1000;
                #video {
                    width: 100% !important;
                    height: 100% !important;
                }
            }
            > div {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }
        }

        .item-video-name {
            font-size: pointSize(45rem);
            margin-top: pointSize(16rem);
        }

        .video-js {
            width: 100%;
            height: 100%;
            background-color: #000;
        }

        .hid {
            > div {
                display: none;
            }
        }

        .btn {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }

        .full-screen {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 30px;
            height: 30px;
            //background-image: url(../../assets/fullscreen.png);
            // background-image: url(../../assets/exitFullScreen.png);
            // background-image: url(../../../public/images/msi/vehicle_map/quanping.png);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100% auto;
            &.full {
                width: 50px;
                height: 50px;
                // background-image: url(../../assets/exitFullScreen.png);
                // background-image: url(../../../public/images/msi/vehicle_map/huanyuan.png);
            }
        }

        .pause {
            > div {
                position: absolute;
                left: 50%;
                top: 50%;
                margin-top: -40px;
                margin-left: -40px;
                &::after {
                    content: '';
                    display: block;
                    width: 80px;
                    height: 80px;
                    border: 4px solid #fff;
                    border-radius: 50%;
                }
                &::before {
                    content: '| |';
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    display: block;
                    font-size: 23px;
                    color: #fff;
                    font-weight: bold;
                }
            }
        }

        .play {
            cursor: pointer;
            background: #000000;
            > div {
                position: absolute;
                left: 50%;
                top: 50%;
                margin-top: -40px;
                margin-left: -40px;
                &::after {
                    content: '';
                    display: block;
                    width: 80px;
                    height: 80px;
                    border: 4px solid #fff;
                    border-radius: 50%;
                }
                &::before {
                    content: '';
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    margin-left: 2px;
                    display: block;
                    border-top: 15px solid transparent;
                    border-bottom: 15px solid transparent;
                    border-left: 20px solid #fff;
                }
            }
        }

        .loading {
            > div {
                &::before {
                    content: '';
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    margin-left: -40px;
                    margin-top: -40px;
                    width: 80px;
                    height: 80px;
                    // background: url(../../assets/loading.png) no-repeat center;
                    background-size: 100% auto;
                    animation: loading 1s linear infinite both;
                }
            }
        }
    }
}

@keyframes loading {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
