<template>
    <div id="app">
        <keep-alive>
            <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive" />
        <div id="sub" v-show="$route.path != '/' && $route.path.indexOf('/vue') != -1"></div>
    </div>
</template>
<script>
import { handleMenuData } from './router/index.js';
export default {
    data() {
        return {};
    },
    mounted() {
        localStorage.getItem('AUTH_TOKEN') && this.userMenuTree();
    },
    methods: {
        userMenuTree() {
            this.instance
                .get('/upms/api/menu/getMenuTree', {})
                .then((res) => {
                    let data = res.data;
                    if (data.code == 200) {
                        localStorage.setItem('userMenu', JSON.stringify(data.data));

                        handleMenuData(data.data);
                    }
                })
                .catch((res) => {
                    console.log(res);
                });
        }
    }
};
</script>

<style>
@import './assets/css/public.css';
html,
body,
#app {
    height: 100%;
}
#app {
    font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* text-align: center; */
    color: #333;
    /* min-width: 1200px; */
    min-width: 1100px;
    min-height: 560px;
    /* min-height: 600px; */
    overflow: auto;
}
.null_data_chart {
    text-align: center;
    padding-top: 0.8rem;
}
/* element样式重置 start */
/* 去掉input尾部上下小箭头 start */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    -o-appearance: none !important;
    -ms-appearance: none !important;
    appearance: none !important;
    margin: 0;
}
input[type='number'] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    -o-appearance: textfield;
    -ms-appearance: textfield;
    appearance: textfield;
}
/* 去掉input尾部上下小箭头 end */

/* 子应用容器 */
#sub {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    /* width:calc(100% - 100px); */
    transform: translate(-50%, -50%);
    z-index: 999;
    height: 100%;
    /* 暂时样式 todo */
    /* top: 55%;
    left: 56%; */
    overflow: scroll;
}
.el-pagination {
    text-align: center !important;
}
.fl {
    float: left;
}
.fr {
    float: right;
}
.clearfix::before,
.clearfix::after {
    content: '';
    display: table;
}
.clearfix::after {
    clear: both;
}
.clearfix {
    *zoom: 1;
}
</style>
